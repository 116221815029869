<template>
	<section class="newsletter-section">
		<div class="row">
			<div class="columns column6">
				<h2>{{ header }}</h2>
				<div v-parse-links v-html="content" />
			</div>
			<div class="columns column6">
				<div id="embedded-form" />
				<!-- Begin Mailchimp Signup Form -->
				<!--
				<div id="mc_embed_signup">
					<form
						id="mc-embedded-subscribe-form"
						action="https://salondenhaag.us14.list-manage.com/subscribe/post?u=7165242cd87f8ee0f74b22b6d&amp;id=91b984b844&amp;f_id=002d9ae0f0%22"
						method="post"
						name="mc-embedded-subscribe-form"
						class="validate newsletter"
						target="_blank"
						novalidate
					>
						<div id="mc_embed_signup_scroll" style="width: 100%">
							<div class="mc-field-group newsletter-form">
								<input
									id="mce-EMAIL"
									type="email"
									value=""
									name="EMAIL"
									class="required email"
									:placeholder="$t('formEmail')"
								/>
								<input
									id="mc-embedded-subscribe"
									type="submit"
									name="subscribe"
									class="button"
									:value="button"
								/>
							</div>
							<div>
								<div id="mce-responses" class="clear">
									<div id="mce-error-response" class="response" style="display: none" />
									<div id="mce-success-response" class="response" style="display: none" />
								</div>

								<div style="position: absolute; left: -5000px" aria-hidden="true">
									<input
										type="text"
										name="b_7165242cd87f8ee0f74b22b6d_91b984b844"
										tabindex="-1"
										value=""
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
								-->
				<!-- end Mailchimp Signup Form -->
			</div>
		</div>
	</section>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
	button: { type: String, default: '' },
});

onBeforeMount(() => {
	window.hbspt.forms.create({
		portalId: '48828539',
		formId: 'dd11095f-d9bf-4827-994f-ba70ada6043a',
		target: '#embedded-form',
	});
});
</script>

<style lang="scss" scoped>
.hs-button.primary.large {
	background: red;
}

.newsletter-section {
	padding: 60px 0;
	margin: 0;
	background: url('~/assets/images/salon-van-fagel-beeldmerk-white.png') no-repeat center center var(--cta-color);
	border-bottom: 14px solid var(--primary-background-color);

	h2,
	div {
		color: #fff;
	}

	h2,
	h3 {
		margin: 0 0 20px;
	}

	form {
		margin-top: 30px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;

		input {
			background: none;
			padding: 18px;
			height: 58px;
			border: 2px solid #fff;
			color: #fff;
			width: 48%;
			max-width: 300px;
			vertical-align: top;

			&::placeholder {
				color: #fff;
			}

			&.button {
				line-height: 22px;
				margin: 0;
				width: auto;
				padding: 14px 30px;
				background: #fff;
				color: var(--cta-color);
				max-width: 200px;

				&:hover {
					background: var(--secondary-background-color);
					color: var(--cta-color);
					border-color: var(--secondary-background-color);
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.newsletter-section {
		form {
			gap: 10px;

			input,
			input.button {
				width: 100%;
				margin: 0 auto 5px;
				max-width: 500px;
			}
		}
	}
}
</style>
