<template>
	<transition :name="`notification-${position}`">
		<div
			v-show="show"
			:style="{ transform: minimized ? `translate(0, calc(100% - ${boxToggleHeight}px + 2px))` : '' }"
			class="notification special-offers-box"
			:class="[
				{
					mobile: notification.showOnMobile,
					tablet: notification.showOnTablet,
					desktop: notification.showOnDesktop,
					minimized,
				},
				position,
			]"
		>
			<div class="notification-row">
				<div ref="header" class="box-toggle">
					<h4 @click="minimize">
						{{ notification.header }}
					</h4>
					<div class="circle">
						<font-awesome-icon
							v-show="!minimized"
							class="icon minimize"
							icon="fas fa-chevron-down"
							size="1x"
							@click="minimize"
						/>
						<font-awesome-icon
							v-show="minimized"
							class="icon close"
							icon="fas fa-xmark"
							size="1x"
							@click="() => (show = false)"
						/>
					</div>
				</div>
				<div v-if="notification.image" class="image">
					<picture>
						<source :srcset="notification.imageWebp" type="image/webp" />
						<source :srcset="notification.image" />
						<img :src="notification.image" />
					</picture>
				</div>
				<div class="body">
					<div v-html="notification.content" />
					<a
						v-if="notification.button"
						target="_blank"
						:href="notification.link"
						class="button button-compact"
						>{{ notification.button }}</a
					>
				</div>
			</div>
			<div v-show="show" class="background close" />
		</div>
	</transition>
</template>

<script setup>
import getTrigger from '@becurious/conversiontriggers';

const props = defineProps({
	notification: { type: Object, default: () => {} },
});

const position = props.notification.conversionPosition;
const show = ref(false);
const minimized = ref(false);
const header = ref(null);
const boxToggleHeight = ref(0);

onMounted(async () => {
	if (!window.sessionStorage || !props.notification) {
		return;
	}

	if (window.sessionStorage.getItem(`displayed-notification-${props.notification.ID}`)) {
		return;
	}

	const showNotification = await getTrigger(props.notification.trigger);
	show.value = await showNotification(props.notification.ID);

	window.sessionStorage.setItem(`displayed-notification-${props.notification.ID}`, 'true');
});

const minimize = () => {
	if (!boxToggleHeight.value) {
		boxToggleHeight.value = header.value.offsetHeight;
	}

	minimized.value = !minimized.value;
};
</script>

<style lang="scss" scoped>
.special-offers-box {
	max-width: 350px;
	transition: all 0.3s ease-in;
	box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);

	&.minimized {
		transform: translate(0, calc(100% - 59px));

		.fa-chevron-down {
			transform: rotate(180deg);
		}
	}

	.body {
		background: var(--primary-background-color);
		padding: 20px;
		width: 100%;
	}

	.box-toggle {
		cursor: pointer;
		background: var(--dark-background-color);
		width: 100%;

		.circle .icon {
			position: absolute;
			right: 15px;
			top: -15px;
			background-color: var(--primary-background-color);
			height: 13px;
			vertical-align: middle;
			width: 13px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px;
		}

		h4 {
			color: #fff;
			letter-spacing: 0;
			font-size: 24px;
			padding: 15px 20px;
			margin-bottom: 0;
		}
	}
}

@media (max-width: 480px) {
	.background {
		background: rgb(0 0 0 / 50%);
		position: fixed;
		inset: 0;
		z-index: 1;
		transition: all 0.4s ease;
	}

	.special-offers-box {
		.notification-row {
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			align-items: stretch;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 900px;
			z-index: 3;
			width: calc(100% - 20px);

			.image {
				width: 100%;

				img {
					object-fit: cover;
					width: 100%;
					max-height: 180px;
				}
			}

			h4 {
				font-size: 20px;
				pointer-events: none;
			}

			.icon.minimize {
				display: none !important;
			}

			.icon.close {
				display: block !important;
			}
		}
	}

	.special-offers-box:not(.mobile) {
		display: none !important;
	}
}

@media (max-width: 801px) {
	.special-offers-box:not(.tablet) {
		display: none !important;
	}
}

@media (min-width: 1025px) {
	.special-offers-box:not(.desktop) {
		display: none !important;
	}
}
</style>
